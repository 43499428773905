<template>
<div class="my-bg">
    <b-container>
    <b-row class="d-flex align-items-center justify-content-center">
        <b-col md="12" sm="12" class="py-3 py-xs-0">
        <div>
            <div class="pt-3">
            <h1 class="font-weight-bolder text-colorBlue">Management</h1>
            </div>
            <div class="pt-2">
            <h4 class="font-weight-normal text-colorGreen">
                Most Extensive & Economical Medical Procedures
            </h4>
            </div>
            <div class="pt-3 button-container d-lg-flex d-block">
            <div>
                <b-button
                pill
                variant="colorGreen"
                size="md"
                class="first-button text-light font-weight-bolder mr-2 mb-1 mb-xs-0"
                :to="{ name: 'Home' }"
                >
                <img
                    src="@/assets/images/icons/medical-cross-fill.svg"
                    class="logo mr-50"
                />
                Back to Home
                </b-button>
            </div>
            <div>
                <b-button
                pill
                variant="colorGreen"
                size="md"
                class="font-weight-bolder"
                :to="{ name: 'ContactUs' }"
                >
                <img
                    src="@/assets/images/icons/medical-cross-stroke.svg"
                    class="mr-50"
                />Book Free Appointment
                </b-button>
            </div>
            </div>
        </div>
        </b-col>
    </b-row>
    </b-container>
</div>
</template>
  
  <script>
  export default {};
  </script>
  
  <style scoped>
  .my-bg {
    background: url("~@/assets/images/management/header.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
  
    background-blend-mode: multiply;
    height: 100%;
    width: 100%;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  .button-container .first-button:hover .logo {
    content: url("~@/assets/images/icons/medical-cross-stroke.svg");
  }
  </style>
  